body {
  font-family: 'Muli', sans-serif !important;
  color: #212121 !important;
  background-color: #fff;
}

.bannerTop {
  z-index: 9999999999999;
  height: 35px;
  margin-top: 75px;
  /* background: rgb(132,204,95);
  background: linear-gradient(90deg, rgba(132,204,95,1) 0%, rgba(116,203,73,1) 52%, rgba(86,223,204,1) 100%); */
  /* background-color: #4183c4; */
  background: rgb(65, 131, 196);
  background: linear-gradient(90deg, rgba(65, 131, 196, 1) 0%, rgba(68, 151, 228, 1) 50%, rgba(86, 196, 223, 1) 100%);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannerTop p {
  /* font-weight: 600 !important; */
  font-size: 14px !important;
}

.ui.menu.fixed .item {
  color: #212121 !important;
  transition: 0.3s;
}

.ui.menu.fixed .item:hover {
  color: #71b54e !important;
}

.ui.inverted.menu .active.item {
  color: #71b54e !important;
  font-weight: 900;
}

.ui.inverted.menu .active.item:hover {
  color: #000 !important;
}

.ui.container {}

p {
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 1.5;
}

hr {
  border: 2px solid #71b54e !important;
}

.fas {
  margin-top: 2px;
}

h1 {
  font-family: 'Muli', sans-serif;
  font-weight: 700;
}

h5 {
  font-family: 'Muli', sans-serif;
  font-weight: 700;
}

h2 {
  font-weight: 700;
  font-family: 'Muli', sans-serif;
  color: #212121;
  font-size: 22px;
}

h3 {
  font-weight: 600 !important;
  font-size: 18px !important;
  font-family: 'Muli', sans-serif;
  margin-top: 0px;
}

h4 {
  font-family: 'Muli', sans-serif;
}

ul {
  font-size: 16px;
  margin-bottom: 30px;
  font-weight: 300 !important;
}

ul li {
  margin-bottom: 6px;
}

.titleRow {
  display: flex !important;
  margin-top: 80px !important;
  justify-content: center !important;
  align-items: center !important;
}

.titleRowLeft h1 {
  font-size: 34px;
  margin: 170px auto 35px 10px;
}

.titleRow h1 {
  margin-top: 50px;
  font-size: 28px;
  font-weight: 900;
}

.ui.icon.input>i.icon {
  right: 10px !important;

}

.ui.fluid.dropdown>.dropdown.icon {
  color: #b5b5b5;
  top: 15px;
  right: 20px;
  font-size: 18px;

}

#strainListSearchInput {
  background-color: #f2f2f2 !important;
  border: 1px solid #ccc;
}

#strainList {
  padding: 0px !important;
  margin: 60px auto 0 auto !important;
}

#strainList h3 {
  font-size: 20px !important;
}

#strainListEmpty {
  margin-top: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column
}

#mobileListItem .container {
  margin: 0px !important;
}


#strainsListContainer .searchInput {
  width: 100% !important;
  display: flex;
  margin: 0 auto;
}

#strainsListContainer {
  background-color: #fefefe !important;
}

#phoneIconStrainFinder {
  font-size: 20px !important;
  margin-right: 4px;

}

#strainFinderDownloadAppTextHead {
  font-weight: 400 !important;
}

#strainsListContainer input {
  border-radius: 100px !important;
  transition: 0.3s !important;
  height: 50px;
  font-size: 18px;
  padding: 20px;
  border: 1px solid #efefef;
  border-bottom: 2px solid #efefef;
}

#strainsListContainer input:focus {
  background-color: #fff !important;
}

#strainFilter {
  color: #212121 !important;
  background-color: #f2f2f2;
  height: 50px;
  border: 1px solid #efefef;
  border-bottom: 2px solid #efefef;
  font-size: 18px;
  transition: 0.3s !important;
  display: flex;
  align-items: center;
  border-radius: 100px !important;
}

#strainFilter:focus {
  background-color: #fff !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
}

.dropdown p {
  margin: 0 0.5em;
  padding: 0px;
  font-size: 18px !important;
  font-weight: 700;
  margin-top: 10px;
}

.dropdown .divider {
  margin-bottom: 10px;
  margin-top: 5px;
}

.ui.selection.dropdown .menu>.item {
  padding: 12.5px 20px !important;
  font-size: 16px;
}

.ui.dropdown .menu>.header {
  background-color: rgb(223, 223, 223);
  padding: 10px 20px !important;
  margin: 0px !important;
}

.ui.dropdown .menu>.item:hover {
  background-color: #efefef;
}

.ui.dropdown .menu .active.item {
  background-color: #54ad38;
  color: #fff;

}

#loadMorebtnContainer {
  width: 100%;
  clear: both;
  display: flex;
  justify-content: 'center'
}

#loadMorebtn {
  padding: 15px 35px;
  margin: 4em auto;
  background-color: #71b54e;
  color: #fff;
  border-radius: 100px;
}

#toTopButton {
  width: 45px;
  height: 45px;
  justify-content: center;
  display: flex !important;
  box-shadow: 0px 2px 10px 0px #00000015;
  background-color: #fff;
  border-radius: 100px;
  position: fixed;
  right: 100px;
  bottom: 30px;
}


#itemTabletPlus {
  transform: scale(0.96);
  box-shadow: 0px 2px 25px 0px #00000008;
  transition: 0.3s;
}

#itemTabletPlus:hover {
  transform: scale(0.94);
  cursor: pointer;
}

.wrapper {
  background-color: #fff;
  padding: 25px !important;
}

.wrapperShadowHome,
.wrapperShadowDispensary {
  background-color: #fff;
  margin-top: -60px !important;
  padding: 15px !important;
  box-shadow: 0px 3px 11px 0px #00000010 !important;
  min-height: 300px;
  border-radius: 30px;
}

.wrapperHome {
  margin-top: -90px !important;
  padding: 10px !important;
  min-height: 300px;
  border-radius: 30px;
}

#header {
  margin-top: 20px;
  padding: 80px 20px;
  z-index: 999;
}

#homeHeaderNetworkBg {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  height: auto;
  width: 550px !important;
  margin-right: 0;
}

#headerStrainList {
  background-color: #fff;
  padding: 110px 10px 25px 10px;
  z-index: 999;
  color: #fff;
}

#headerStrainList h2 {
  font-size: 20px;
}

#header h1 {
  font-weight: 700;
  font-size: 34px;
}

#header h2 {
  margin-top: -10px;
  font-weight: 200;
}

#header img {
  margin-top: -40px !important;
  width: 90%;
  margin: 0 5%;
  padding: 0;
}

#headImg {
  z-index: 99;
  margin: 0
}


#filterContainer {
  margin-bottom: 2em !important;
  padding: 10px;
}

#filterContainer .column {
  margin: -5px auto !important
}

.dispCircles {
  display: none;
}

#headerDispensary {
  color: rgb(77 77 77);
  height: 500px;
  margin-top: 70px;
  padding: 0 30px;
  display: flex !important;
  align-items: flex-start !important;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

#dispHeaderOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

#headerDispensary h1 {
  margin-top: 100px;
  font-size: 54px;
  font-weight: 700;
  z-index: 9;
  font-size: 38px;
}

#headerDispensary span {
  color: #71b54e;
  font-weight: 200;
}

.ctaButtonHead {
  background-color: #fff !important;
  border-radius: 50px !important;
  box-shadow: 0px 3px 3px 0px #00000020 !important;
  border-radius: 50px !important;
  color: #71b54e !important;
  padding: 15px 35px !important;
  margin-top: 10px !important;
}

#headerDispensary p {
  margin-top: -2px;
  z-index: 9;
  font-size: 22px !important;
}

#headerDispensary img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 100;
  z-index: -9;
}

#whyChooseUs {
  margin: 20px 0;
}

.whyChooseUsBox {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  padding: 20px;
  webkit-box-shadow: 0 0 0 0 rgba(34, 36, 38, .15) !important;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, .15) !important;
}

.circleIconWrap {
  min-width: 90px;
  height: 90px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
}

.whyChooseUsBox p {
  width: 90%;
  margin-top: -8px;
}

.ui.menu.fixed .textLi {
  font-size: 16px;
  padding: 10px;
  font-weight: 500;

}

#navLinkDownloadApp {
  height: 38px;
  width: 140px;
  margin-top: 9px;
  margin-left: 10px;
  border-left: 2px solid rgb(218, 218, 218);
  justify-content: center;
  align-items: center;
}

#navLinkDownloadApp:hover {
  background-color: #71b54e;
  border-left: 2px solid #71b54e;
  color: white !important;
  border-radius: 50px;
}

.footer .textLi {
  font-size: 16px;
  padding: 10px;
}

.ui.menu.fixed,
.ui.menu.fixed .item:first-child,
.ui.menu.fixed .item:last-child {
  color: white
}


#headerText {
  text-align: left;
}

.descLegend {
  color: #cccccc !important;
  font-size: 14px;
  margin-top: -10px;
  margin-left: 10px;
}

.ui.form .field input,
.ui.form .field textarea {
  outline: none !important;
  color: white
}

.ui.form .field input:focus,
.ui.form .field textarea:focus {
  border-color: rgba(121, 204, 96, 0.65) !important;
  color: white
}

.ui.fluid.dropdown {
  color: #fff;
}

.ui.selection.active.dropdown .menu {
  min-height: 300px;
}

.addNew {
  background-color: #000 !important;
  color: #fff !important
}

.addNew i {
  color: #71b54e !important
}

.searchInput {
  margin: 1em 2.5%;
  width: 95%
}

.addNew:hover {
  background-color: #66aa52 !important;
  color: #fff !important
}

.addNew:hover i {
  color: #fff !important
}


.primaryBtn {
  background-color: #71b54e !important;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 15px 25px !important;
}

.buttonPrimary {
  background-color: #71b54e !important;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 15px 25px !important;
}

.editBtn {
  background-color: #71b54e !important;
  color: #fff !important
}

.editBtn:hover {
  background-color: #66aa52 !important
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  transition: .3s;
  border: 1px dashed #4e4e4e;
  background-color: #2f2f2f !important;
  color: #424242 !important;
  font-weight: 800;
  border-radius: 3px;
  display: inline-block;
  padding: 80px 0px;
  width: 140px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 26px;
}

.custom-file-upload:hover {
  border: 1px dashed #747474;
  background-color: #2d2d2d !important;
  color: #424242 !important;
}

.emptyData .ui.icon.header .icon {
  transform: scale(0.85) !important;
}

.emptyData p {
  font-size: 15px !important;
  font-family: 'Muli';
}

.tabBoxes {
  margin: 2em 5px 0px 5px !important;
}

.tabBoxes h3 {
  margin: 0 0 0 15px;
  color: #000 !important;
}

.tabBoxes p {
  margin: 15px 0 20px 0;
  font-weight: 300 !important;
  font-size: 16px !important;
  /* color: #000 !important; */
}

.duo .tabBox p {
  padding: 0 0 50px 0 !important;
}

.tabBox {
  padding: 28px 20px 20px 20px !important;
  margin: auto -5px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  /* background-color: #71b54e; */
  background-color: #fff;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.04); */
  box-shadow: 0px 2px 25px 0px #00000008;
  border: 1px solid #c6c6c640 !important;
}

.tabBoxAlt {
  padding: 45px 20px 30px 25px !important;
  margin: auto -5px !important;
  border: 1px solid rgb(243, 243, 243) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  background-color: #5e4fb530;
  backdrop-filter: blur(4px);
}

.tabBoxAlt h2 {
  margin: -10px 0 -5px 10px;
  color: #212121 !important;
}

.tabBoxAlt i {
  color: #5e4fb5 !important;
}


.tabBoxAlt button {
  background-color: #5e4fb5 !important;
}

#smallParagraphDate {
  font-size: 12px !important;
}

/* @media (min-width: 640px){
    
} */

#header {
  margin-top: 80px;
  padding: 30px 15px 140px 15px;
  z-index: 999;
}

@media (min-width: 768px) {
  #header {
    margin-top: 80px;
    padding: 90px 15px 200px 15px;
    z-index: 999;
  }

  #header #headImg img {
    min-width: 360px;
  }

  .wrapperHome {
    margin-top: -120px !important;
    padding: 25px !important;
  }

  .dispCircles {
    display: block;
  }

  .wrapperShadowHome {
    padding: 20px !important;
  }

  .ui.container {
    width: 100% !important;
    margin: auto !important;
  }

  #homeHeaderNetworkBg {
    display: block;
    opacity: 0.5
  }

  #headerDispensary {
    height: 450px;
    padding-top: 60px;
  }

  #headerText {
    padding: 40px;
  }

  #header h1 {
    font-size: 38px;
    font-weight: 700;
  }

  #headerDispensary h1 {
    font-size: 50px;
    margin-top: 70px;
  }

  .titleRow h1 {
    margin-top: 70px;
    font-size: 32px;
    font-weight: 700;
  }

  .titleRowLeft h1 {
    font-size: 42px;
    margin: 100px 0 -10px 50px;
    font-weight: 700;
  }

  #header img {
    margin-right: 4px;
    margin-top: 30px;
    margin: 30px 0 0 10px;
  }

  .tabBox,
  .tabBoxAlt {
    margin: 10px -5px !important;
  }
}

@media (min-width: 1024px) {
  .dispCircles {
    display: block;
  }

  .ui.container {
    width: 1127px !important;
    margin: auto !important;
  }

  #header {
    margin-top: 0px;
    z-index: 999;
  }

  #headerStrainList {
    padding: 145px 10px 25px 10px;
  }

  #filterContainer {
    margin-bottom: 5em !important;
  }

  #homeHeaderNetworkBg {
    opacity: 0.5
  }

  #headImg {
    display: block !important;
  }

  #header h2,
  #headerStrainList h2 {
    font-size: 20px;
  }

  #header img {
    margin-right: 4px;
    margin: 30px 0 0 0px
  }

  .wrapperShadowHome {
    background-color: #fff;
    z-index: 99;
    margin-top: -250px !important;
    box-shadow: 0px 3px 3px 0px #00000020;
  }

  .wrapperShadowDispensary {
    background-color: #fff;
    z-index: 99;
    margin-top: -150px !important;
    box-shadow: 0px 3px 3px 0px #00000020;
    border-radius: 50px;
  }

  #headerDispensary {
    height: 600px;
    padding-top: 30px;
    align-items: center !important;
  }

  #headerDispensary h1 {
    font-size: 50px;
    margin-top: 120px;
  }

  .tabBox,
  .tabBoxAlt {
    height: 100%;
  }
}

@media (min-width: 1200px) {
  #header {
    /* //make 95 if no banner on top... */
    height: 100vh;
    padding: 70px 15px 200px 15px;
    /* background-color: red; */
  }

  #header h1,
  #headerDispensary h1 {
    font-size: 48px;
    margin-bottom: 22px;
  }

  #homeHeaderNetworkBg {
    width: 620px !important;
  }

  #header #headImg img {
    min-width: 470px;
  }

  .ui.container {
    width: 1200px !important
  }
}

@media screen and (min-width: 1920px) {
  #header {
    /* //make 95 if no banner on top... */
    height: 90vh;
    /* background-color: red; */
  }
  /* //For Projectors or Higher Resolution Screens (Full HD) */
  #header h1,
  #headerDispensary h1 {
    font-size: 52px;
  }

  #header #headImg img {
    min-width: 570px;
  }
}

@media screen and (min-width: 3840px) {

  /* //For 4K Displays (Ultra HD) */
  #header h1,
  #headerDispensary h1 {
    font-size: 58px;
  }

  #header #headImg img {
    min-width: 680px;
  }
}