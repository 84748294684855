* {
  box-sizing: border-box;
}
.contactForm {
   max-width: 700px;
   width: 90%;
   margin: 0px auto;
}

.contactForm h1 {
   margin-bottom: 30px;
}
.contactForm .subscribeh1 {
   margin-bottom: 10px;
}

input {
   height: 46px;
 }

 label {
    font-size: 16px !important;
    font-weight: 700 !important;
    /* margin-bottom: 10px !important */
 }

 ::placeholder {
   color: rgb(143, 143, 143) !important;
   opacity: 1; /* Firefox */
 }

#contact-form input[type=text],input[type=email], select, textarea {
   background-color: #f5f5f5 !important;
   width: 100%;
   padding: 12px;
   border: 0px solid #ccc !important;
   border-radius: 4px;
   box-sizing: border-box;
   margin-top: 6px !important;
   /* margin-bottom: 10px !important; */
   resize: vertical;
}
input[type=submit] {
   background-color: #4CAF50;
   color: white;
   padding: 12px 20px;
   border: none;
   border-radius: 4px;
   cursor: pointer;
   margin-bottom: 50px;
}
input[type=submit]:hover {
   background-color: #45a049;
}
.ui.form .field input, .ui.form .field textarea {
   outline: none !important;
   color: #212121 !important;
}

.ui.form .field>label {
   margin-top: 25px !important;
}
/* .container { */
   /* border-radius: 5px; */
   /* background-color: #f2f2f2; */
   /* padding: 20px; */
/* } */