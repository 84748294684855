#headerStain {
  display: flex;
  padding: 150px 0 80px 0;;
  /* height: 420px; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#headerStain h1 {
  color: #3d3d3d;
  font-size:  36px;
  font-weight: 900;
  margin-top: -20px;
  text-align: center;
}
#headerStain h2 {
  margin-top: -15px;
  color: #3d3d3d;
}

h3 {
  font-weight: 700 !important;
  font-size: 16px !important;
  margin-bottom: 6px;
  /* font-family: 'Source Sans Pro', sans-serif; */
}

p {
  font-weight: 200;
  /* font-size: 18px !important; */
}

#strainContain {
  background-color: #fff;
  /* margin-top: -70px !important; */
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  padding: 10px !important;
  /* box-shadow: 0px 3px 30px 0px #00000010; */
  /* border: 1px solid #eaeaea; */
  min-height: 300px;
}


#strainContain .segment {
  margin-bottom: 30px;
  border-width: 0px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 20px 20px rgba(0,0,0,0.025)inset;
  -moz-box-shadow: 0px 0px 20px 20px rgba(0,0,0,0.025)inset;
  box-shadow: 0px 0px 20px 20px rgba(0,0,0,0.025)inset;
}
#strainDataCountContainer {
  margin-top: -2px;
}
#strainDataCountContainer h3 {
  color: #3d3d3d;
  font-weight: 400 !important;
  /* font-family: 'Source Sans Pro', sans-serif; */
  font-size: 18px !important;
  margin: 0 0 0 6px;
}
#strainDataCountContainer .icon {
  color: rgba(0, 0, 0, 0.3);
  font-size: 18px !important;
}

#strainValuesContainer h3{
  margin: 0 0 0 6px;
  margin-right: 10px;
}
#strainValuesContainer div{
  /* padding: 0px 10px 0 10px; */
  margin-bottom: 20px;
}
#strainValuesContainer i{
  padding: 8px 4px 8px 8px;
  font-size: 20px !important
}
#strainValuesContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.effectMedText {
  font-size: 15px !important;
  font-weight: 600 !important;
}

#strainImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4em 0 !important;
}
#strainImage {
  width: 50%;
  max-width: 200px;
  margin: 0 auto;
  display: flex;
  /* height: auto; */
}
#strainImageGrid div.column {
  padding: 0.5px !important;
}
#strainGridImage {
  transition: 0.3s;
  aspect-ratio: 1;
  object-fit: cover;
}
#strainGridImage:hover {
  /* transform: scale(0.98); */
  cursor: pointer;
  background-color: #3d3d3d;
}

.ui.comments .comment .avatar img, .ui.comments .comment img.avatar {
  border-radius: 100px !important;
  width: 50px;
  height: 50px;
}
.ui.comments .comment>.avatar~.content {
  margin-left: 4.5em;
}